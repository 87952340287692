body {
  margin: 0;
  font-family: "Roboto"
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;
